import React, { useState, useEffect } from "react";
import { Button, Input,  } from "reactstrap";
import rightIcon from "../../assets/icon/pagination-right.svg";
import leftIcon from "../../assets/icon/pagination-left.svg";
import endIcon from "../../assets/icon/pagination-end.svg";
import firstIcon from "../../assets/icon/pagination-first.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

const Pagination = ({ currentPage, totalRecords, onPageChange, pageSize, hasNoEligibility = true, researchNeededCount, eligibleCount, inEligibleCount }) => {
  const [state, setState] = useState({
    value: "",
    error: ''
  });
  useEffect(() => {
    setState(pre => ({ ...pre, value: currentPage }))
  }, [currentPage])

  const getTotalPage = (total, pageSize) => {
    const totalPage = total / pageSize;
    const page = Math.floor(total / pageSize);
    const splitted = totalPage.toString().split('.');
    if (splitted[1]) {
      return page + 1
    } else {
      return page;
    }
  };



  const onPageNext = (value) => {
    switch (value) {
      case "NEXT":
        const page = currentPage + 1;
        if (page <= getTotalPage(totalRecords, pageSize)) {
          setState(pre => ({ ...pre, value: page }))
          return onPageChange(page);
        }
        break;
      case "PREVIOUS":
        const previousPage = currentPage - 1;
        if (previousPage >= 1) {
          setState(pre => ({ ...pre, value: previousPage }))
          return onPageChange(previousPage);
        }
        break;
      default:
        break;
    }
  };

  const onInputChange = (e) => {
    const { value } = e.target;
    if (parseInt(value) === 0) return setState(pre => ({ ...pre, value, error: 'Enter proper Page number' }));
    if (value === '') return setState(pre => ({ ...pre, value: '', error: 'Enter proper Page number' }));
    if (value > getTotalPage(totalRecords, pageSize)) return setState(pre => ({ ...pre, value, error: 'Enter proper Page number' }));;
    setState((pre) => ({ ...pre, value, error: '' }));
  };

  const onKeyEnterPage = e => {
    const { key } = e;
    const { value, error } = state;
    if (key === 'Enter' && !error) {
      onPageChange(parseInt(value))
    }
  }
  const handlePageSizeChange = (e, filters, isHaveColFilter) => {
    const { value } = e.target;
    const pageFilter = {
      ...filters,
      currentPage: 1,
      pageSize: parseInt(value),
    };
    if (isHaveColFilter) {
      let colFilterOn = { ...pageFilter, filters: columnFilters };
      return onPageChange(colFilterOn, true, columnChangedFilters);
    }
    onPageChange(pageFilter);
  };

  // const handlePageSizeChange= (e)=>{
  //   const {value}= e.target
  //   setItemsperPage(value)
  // }
  // console.log(iemsperpage)

  return (
    <div className="d-flex justify-content-between align-items-center rcm-pagination">
      <div className="d-flex align-items-center">
        <div className="">
          <span className="text-danger">{state.error}</span>
        </div>

        <div hidden={hasNoEligibility}>
          <span>Research Required : </span> {researchNeededCount} &nbsp;&nbsp;
          <span>Eligible : </span> {eligibleCount} &nbsp;&nbsp;
          <span>Ineligible : </span> {inEligibleCount} &nbsp;&nbsp;
        </div>


        {/* <Button
          disabled={currentPage === 1 || totalRecords === 0}
          onClick={() => onPageChange(1)}
          color="link"
          className="px-0"
        >
          <img src={firstIcon} alt="firstIcon" />
        </Button> */}
        <Button
          disabled={currentPage === 1 || totalRecords === 0}
          onClick={() => onPageNext("PREVIOUS")}
          color="link"
          className="px-0"
        >
          {/* <img src={leftIcon} alt="leftIcon" /> */}
          <FontAwesomeIcon icon={faAngleLeft} className="fa-fw" />
        </Button>
        <div>
          {/* <Input
            type="number"
            value={state.value}
            onChange={onInputChange}
            className="mx-2"
            onKeyDown={onKeyEnterPage}
            disabled={totalRecords === 0}
          /> */}
        </div>
        <div className="font-size-16 pe-2">
          <ul className="Pagination_list">
            <li className="active">01</li>
            <li>02</li>
            <li>03</li>
            <li>04</li>
            <li>05</li>
            <li>06</li>
            <li>....</li>
            <li>{getTotalPage(totalRecords, pageSize)}</li>

          </ul>
          <span>   
            {/* {getTotalPage(totalRecords, pageSize)} */}
          </span>
          <span>
            {/* ({totalRecords}) */}
          </span>
        </div>
        <Button
          color="link"
          disabled={currentPage === getTotalPage(totalRecords, pageSize) || totalRecords === 0}
          onClick={() => onPageNext("NEXT")}
          className="px-0"
        >
          {/* <img src={rightIcon} /> */}
          <FontAwesomeIcon icon={faAngleRight} className="fa-fw" />
        </Button>

       


        {/* <Button
          disabled={currentPage === getTotalPage(totalRecords, pageSize) || totalRecords === 0}
          onClick={() => onPageChange(getTotalPage(totalRecords, pageSize))}
          color="link"
          className="px-0"
        >
          <img src={endIcon} />
        </Button> */}
      </div>

      <Input
        type='select'
        // onChange={handlePageSizeChange}
        bsSize='sm'
        className='display-select d-inline-block'
        value={pageSize}
      >
        <option value={10}>10</option>
        <option value={25}>25</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </Input>
    </div>
  );
};

export default Pagination;
