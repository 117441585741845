import { cloneDeep, orderBy } from 'lodash';
import Reports from 'pages/ConfigurationsTabs/Reports/Index';

const defaultClaimsFilter = {
  currentPage: 1,
  pageSize: 10,
  order: 'desc',
  orderBy: 'ceReceivable',
  filters: [],
};
const defaultReports={
  currentPage:1,
  pageSize:10,
  order:"desc",
  orderBy:"ceReceivablel",
  filters:[]
}
const defaultConfigurations={
  currentPage:1,
  pageSize:10,
  order:"desc",
  orderBy:"createddate",
  filters:[]
}
const defaultStates = {
  claimsManager: {
    hasFilterd: false,
    search: cloneDeep(defaultClaimsFilter),
    pharmacies: [],
    patients: [],
    claimStatus: {},
    coveredEntity: {},
    pharmacy: {},
    statusname: {},
    searchPatientTerm: '',
    patient: '',
    provider: '',
    searchTerms: {
      coveredEntityID: '',
      pharmacyID: '',
      patientID: '',
      rxNumber: '',
      providerID: '',
      providerFirstName: '',
      startDate: '',
      endDate: '',
      claimStatusID: '',
      pharmacyIds: {},
      claimStatusIds: {}
    },
  },
  configurations: {
    hasFilterd: false,
    search: cloneDeep(defaultConfigurations),
    coveredEntities: [],
    Applications: [],
    ReportType: [],
    coveredEntity: {},
    Application: {},
    ReportType: {},
    searchTerms: {
      coveredEntityIDs: {},
      ApplicationId:{},
      ReportTypeId: {},
      
    }
  },
  reports: {
    hasFilterd: false,
    search: cloneDeep(defaultReports),
    coveredEntities: [],
    Applications: [],
    ReportType: [],
    coveredEntity: {},
    Application: {},
    ReportType: {},
    searchTerms: {
      coveredEntityIDs: {},
      ApplicationId:{},
      ReportTypeId: {},
      startDate: '',
      endDate: '',
      
    }
  },
};

const initialStates = {
  loading: false,
  claimsManager: cloneDeep(defaultStates.claimsManager),
  configuration:cloneDeep(defaultStates.configurations),
  reports:cloneDeep(defaultStates.reports)

};

export { initialStates, defaultStates };
