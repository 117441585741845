import ReactStrapTable from 'components/reactStrapTable';
import React, { useState } from 'react'

// import ConfigIcon from 'assets/icon/icon-icon-options-view-patient.svg';
// import ConfigIconHover from 'assets/icon/icon-icon-options-view-patient_hover.svg';
import Download from 'assets/icon/Download.svg';
import DownloadHover from "assets/icon/download_hover.svg";
import HoverImage from 'react-hover-image/build';
import { Button } from 'reactstrap';
import { dateFormate, currencyFormat, cellRendererCheck } from 'utils/columns';
import { onExport } from 'utils/helper';
// import axios from 'axios';
import useAxios from 'hooks/useAxios';
import Loader from 'components/Loader';






let tableDataBackup = [];

const ReportsTable = (props) => {
    const {

        manvalConfigedit,
        manvalConfig,
        manvalPharmacy,
        collapseSider,
        manval,
        reportsdata,
        loading,
      
    } = props;
    tableDataBackup=reportsdata

    const API = useAxios();

    const [tableData, setTableData] = useState(tableDataBackup);
 console.log(reportsdata,"reportsdatagopi")


    const handleConfig = async (rowData) => {
        await manvalConfig();

    }
    const toggleSider = async () =>{
     await manval();
    }

    
 
    const handelAddCoveredEntity = async (rowData) => {
        await manvalPharmacy();

    }
    const handleEditConfig = async () => {
        await manvalConfigedit();
    }

    const handleonExport = () => {
        onExport()
    }
    const renderEditButton = (rowData) => {
        // if (hasPermission('EDT'))
        return <Button
            className='text-primary p-0 text-decoration-none'
            color='link'
            onClick={() => handleExport(rowData)}
             title='Download Report'

        >
            <HoverImage src={Download}
                hoverSrc={DownloadHover}
                alt='' />
        </Button>
    }

    // const renderConfigButton = () => {

    //     return <Button
    //         className='text-primary p-0 text-decoration-none'
    //         color='link'
    //         onClick={() => handelAddCoveredEntity()}
    //         title='view Icon'

    //     >
    //         <HoverImage
    //             src={ConfigIcon}
    //             hoverSrc={ConfigIconHover}
    //             alt='' />
    //     </Button>
    // }
    const handleExport = async (rowData) => {
        try {
            const response = await API.get(`api/Report/download/${rowData?.reportDetailsID}`, {
                hasDefaultURL: true,
                responseType: 'blob'
            },
             );
    
            // Handle the response, e.g., save the file
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${rowData.fileName}`); // or the appropriate file name
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.log(error);
        }
    };
    const cellRenderEdit = (rowData) => {
        return (
            <div className='d-flex align-items-center'>
                {/* {renderConfigButton(rowData)} */}
                {renderEditButton(rowData)}
                {/* {renderCommentsButton(rowData)} */}
                {/* {renderViewButton(rowData)} */}
            </div>
        );
    };

  


    let mainCol = [
        {
            dataField: 'edit',
            text: 'Options',
            cellRenderer: (rowData) => cellRenderEdit(rowData),
            width: 100,
            show: true,
            disabled: true,
            hideColFilter: true,
            action: true,
        },

        {
            dataField: 'reportName',
            text: 'Report Name',
            sort: true,
            width: 140,
            show: true,
            inputType: 'text',
            hideColFilter: true,
            cellRenderer: (row) => cellRendererCheck(row.reportName),
        },
        {
            dataField: 'reportTypeName',
            text: 'Report Type',
            sort: false,
            width: 140,
            show: true,
            inputType: 'text',
            hideColFilter: true,
            cellRenderer: (row) => cellRendererCheck(row.reportTypeName),
        },
        {
            dataField: 'fileName',
            text: 'File Name',
            sort: false,
            width: 200,
            show: true,
            inputType: 'text',
            hideColFilter: true, 
            cellRenderer: (row) => cellRendererCheck(row.fileName),
        },
        {
            dataField: 'numberOfRecords',
            text: 'No. of Records',
            sort: true,
            width: 200,
            show: true,
            inputType: 'text',
            hideColFilter: true,
            cellRenderer: (row) => cellRendererCheck(row.numberOfRecords==0?"0":row.numberOfRecords),
        },
        {
            dataField: 'statusDesc',
            text: 'Report Status',
            sort: false,
            width: 200,
            show: true,
            inputType: 'text',
            hideColFilter: true,
            cellRenderer: (row) => cellRendererCheck(row.statusDesc),
        },
        {
            dataField: 'retryCount',
            text: 'Retry Count',
            sort: false,
            width: 140,
            show: true,
            inputType: 'text',
            hideColFilter: true,
            cellRenderer: (row) => cellRendererCheck(row.retryCount==0?"0":row.retryCount),
        },
        {
            dataField: 'dateGenerated',
            text: 'Data Generated',
            sort: false,
            width: 140,
            show: true,
            inputType: 'text',
            hideColFilter: true,
            cellRenderer: (row) => dateFormate(row.dateGenerated),
        },

    ];
    return (

        <div>
            {/* {loading&&<Loader opacity={0.01}color='black'/>} */}
            <div
                className='table-card scroll-table position-relative border-0 rounded pt-0'
                fixed-height={reportsdata.length > 0 ? 'fixed-height' : 'default'}
            >
                <ReactStrapTable
                    id='configuration-table'
                    height={550}
                    fixedColumn
                    width={1200}
                    // reset={reset}
                    data={reportsdata}
                    columns={mainCol}
                    // add={"Add Covered Entity"}
                    // addbutton={true}
                    handelAddCoveredEntity={handelAddCoveredEntity}
                    onExport={handleonExport}
                    // filters={2}
                    totalRecords={10}
                    pageSize={20}
                    currentPage={1}
                    hasExportPermission={true}
                    collapseSider={collapseSider}
                  
               
                    toggleSider={toggleSider}
                   


                // handleSort={onHandleSort}
                // onPageChange={onHandlePageChange}
                // onPageSizeChange={onPageSizeChange}
                // onFilterCancel={handleFilterCancel}
                // onSearchColumnFilters={handleSearchColumnFilters}

                />
            </div>
        </div>
    )
}

export default ReportsTable