import React, { useEffect, useState } from "react";
import PharmaLogImage from "../../assets/images/pharma-logo.svg";

const Logo = (props) => {
  const { height, width } = props;
  const theme = sessionStorage.getItem('theme') || "PF";
  return (
    <img src={theme === "PF" ? PharmaLogImage : LogoImage} alt="logo" height={height} width={width} />
  );

};

export default Logo;
