
import React, { useEffect, useState } from 'react'
// import EditIcon from 'assets/icon/icon-icon-options-edit.svg';
// import EditHoverIcon from 'assets/icon/icon-icon-options-edit_hover.svg';
import HoverImage from 'react-hover-image/build';
import { Button, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from 'reactstrap';
import { dateFormate, currencyFormat, cellRendererCheck } from 'utils/columns';
// import CancleIcon from 'assets/icon/icon-icon-options-not-eligible.svg';
import Calendar from 'assets/icon/calendar-icon.svg';
// import SaveIcon from 'assets/icon/icon-icon-options-order-complete.svg';
// import CellEditRender from 'components/reactStrapTable/components/CellEditRender';
// import CancleHoverIcon from 'assets/icon/icon-icon-options-not-eligible-hover.svg';
// import SaveHoverIcon from 'assets/icon/icon-icon-options-order-complete-hover.svg';
import { Field, Formik } from 'formik';
import ReactSelect from 'react-select';
import FormikDatePicker from 'components/common/Fields/FormikDatePicker';
import reset from 'assets/icon/reset.svg';
import search from 'assets/icon/search.svg';
import moment from 'moment';
import FormikSelectField from 'components/common/Fields/FormikSelectField';
import useContextService from 'hooks/useContextService';
//import axios from 'axios';
import useAxios from 'hooks/useAxios';







let hasDatePopupOpen = false;

let tableDataBackup = [
    {
        chainPharmacy: "123445",
        pharmacy: "123445",
        npi: "123445",
        ncpdPandNABP: "123445",
        pharmacyAddress: "123445",
        pharmacyLiveDate: "123445",
        pharmacyTerminationDate: "123445",
        claimsProcessDate: "123445",

    }
];
let rowForEdit = {};
let updatedFields = {};
const application = [
    { applicationid: 101, applicationName: 'Contract Pharmacy' },
    // { applicationid: 102, applicationName: 'Split Building' },
    // { applicationid: 103, applicationName: 'Contract Pharmacy 2.0' }
]
const SearchContainer = (props) => {
    const {
        // manval,
        // manvalConfigedit,
        // manvalConfig,
        onSearchReports,
        onReset,
        selectedcoveredEntity
    } = props;
    const {
        states: { reports },
        setStates,

        resetStates,
    } = useContextService();
    const { searchTerms, } = reports;

    const API = useAxios();

    const [tableData, setTableData] = useState([]);
    const [data, setData] = useState([]);
    const [showDetails, setShowDetails] = useState(true)
    const [startDateValue, setStartdate] = useState(null);
    const [endDateValue, setEndDate] = useState(null);
    const [ceid, setceid] = useState()
    const [appid, setAppid] = useState(application[0].applicationid)
    const [reportType, setReportType] = useState([])
    const [reportid, setReportid] = useState()
    const [error, setError] = useState(false)
    const [coveredEntityData, setCoveredEntityData] = useState([])
    const updateStates = (reports) => {
        setStates('reports', reports);
    };
    // const [collapseSider, setcollapseSider] = useState(false);
    // const [collIcon, setCollIcon] = useState(faAngleUp);

    // const toggleSider = () => {
    //     setcollapseSider(!collapseSider);
    //     if (!collapseSider) {
    //         setCollIcon(faAngleDown);
    //     }
    //     else {
    //         setCollIcon(faAngleUp);
    //     }
    // }


    const renderEditButton = () => {
        // if (hasPermission('EDT'))

        return <Button
            className='text-primary p-0 text-decoration-none'
            color='link'
            onClick={() => ""}
            title='Edit Covered Entity'

        >
            <HoverImage src={EditIcon}
                hoverSrc={EditHoverIcon}
                alt='' />
        </Button>
    }
    const cellRenderEdit = (rowData) => {

        if (rowData.isEdit) {
            return (
                <div className='d-flex align-items-center'>
                    <Button
                        className='text-primary p-0 text-decoration-none'
                        color='link'
                        onClick={savePanelUpdateRow}
                    >
                        <HoverImage
                            key='SaveIcon'
                            src={SaveIcon}
                            hoverSrc={SaveHoverIcon}
                            alt=''
                        />
                    </Button>
                    <Button
                        className='text-primary p-0 text-decoration-none'
                        color='link'
                        onClick={() => editRow(rowData, false)}
                    >
                        <HoverImage
                            key='CancelIcon'
                            src={CancleIcon}
                            hoverSrc={CancleHoverIcon}
                            alt=''
                        />
                    </Button>
                </div>
            );
        } else {

            return (
                <div className='d-flex align-items-center'>
                    {renderEditLButton1(rowData)}

                </div>
            );
        }
    };
    const renderEditLButton1 = (rowData) => {
        // if (hasPermission('EPLS'))
        return <Button
            className='text-primary p-0 text-decoration-none'
            color='link'

            onClick={() => editRow(rowData)}
            title='Edit Location'


        >
            <HoverImage src={EditIcon}
                hoverSrc={EditHoverIcon}
                alt='' />
        </Button>
    }
    const editRow = (rowData, isEdit = true) => {

        let tempData = [...data];
        tempData = tempData.map((row) => {
            row.isEdit = false;
            if (row.ceServiceLocationID === rowData?.ceServiceLocationID && isEdit) {
                row.isEdit = isEdit;
                rowForEdit = row;
            }
            return row;
        });
        // setTouched('');
        // setSelectedStatus('');
        updatedFields = {};
        //  tableDataBackup = tempData;
        setData(tempData);
    };
    const savePanelUpdateRow = async () => {
        console.log("krishna")
    };
    const updateRowValue = (value, col) => {

        const tempRow = { ...rowForEdit };
        tempRow[col] = value;
        rowForEdit = tempRow;
    };
    const formatter = (row, col, formatterType, alwaysEditableCheckbox) => {

        return (
            <CellEditRender
                row={row}
                col={col}
                formatterType={formatterType}
                onChange={updateRowValue}
                alwaysEditableCheckbox={alwaysEditableCheckbox}
            />
        );
    };
    const fetchCoveredEntities = async () => {
        try {
            const { data } = await API.get('api/CP/configured'
                  , {
                    hasDefaultURL: true,
                }
            );
            if (data) {
                console.log(data)
                setCoveredEntityData(data)
            }
        } catch (error) {
            'failed to load data'
        }
    };
    useEffect(() => {
        fetchCoveredEntities();
        // fetchPharmacy();
    }, [selectedcoveredEntity])

    useEffect(()=>{
        fetchReportType();
      },[appid])
      
    const handelAdd = () => {
        setShowDetails(false)
    }
    const handelCancel = () => {
        setShowDetails(true)
    }
    useEffect(() => {

    }, [showDetails])
    const handleMouseLeave = () => {
        hasDatePopupOpen = false;
    };

    const handleDateClick = (id) => {

        if (hasDatePopupOpen) {
            hasDatePopupOpen = false;
            return;
        }
        const calendarButton = document.getElementById(id);
        if (calendarButton) {
            calendarButton.click();
            hasDatePopupOpen = true;
        }
    };

    const handleStartDate = (StartDate) => {
        if(StartDate){
      setStartdate(new Date(StartDate)
    )}
      else{
        setStartdate('')
      }
    };

    const handleEndDate = (EndDate) => {
        if(EndDate){
        setEndDate(new Date(EndDate))
        }
        else{
            setEndDate('')
        }
      };


    const [focusedInput, setFocusedInput] = useState('');

    const handleFocus = (inputName) => {
        setFocusedInput(inputName);
    };

    const handleBlur = () => {
        setFocusedInput('');
    };
    const handleAppChange = (e) => {
        const { value } = e.target;
        setAppid(value)
        if (appid) {
            fetchReportType(appid)
        }
        else {
            setReportType([])

        }
    }
    const fetchReportType = async () => {
        try {
            const { data } = await API.get(`api/Report/types/${appid}`
                  , {
                    hasDefaultURL: true,
                }
            );
            console.log(data)
            setReportType(data)

        } catch (error) {
            'failed to load data'
        }
    };
    const handleReportChange = (e) => {
        const { value } = e.target;
        console.log(value, "value")
        setReportid(value);
    }
    const handleCeChange = (e) => {

        const { value } = e.target;
        console.log(value, "value")
        setceid(value);
        // purchaseOrder.searchTerms.orderStatusId = value;
        // updateStates(purchaseOrder);
    };
    const handleSubmit = async (val, actions,) => {
        let values = {};
        values.ApplicationId = appid,
            values.coveredEntityID = ceid,
            values.ReportTypeId = reportid
            values.StartDate=startDateValue
            values.EndDate=endDateValue

        // serIsSubmitted(true);

        // const { setSubmitting } = actions;
        //const values = cloneDeep(val);

        // if (coveredEntityID) values.coveredEntityID = coveredEntityID;

        reports.search.currentPage = 1;
        updateStates(reports);
        if (appid == '' || appid == undefined || ceid == '' || ceid == undefined) {
            setError(true)
        }
        else {
            setError(false)
            await onSearchReports(values);
        }
        // setSubmitting(false);

    };

    const handleReset = () => {
        setceid("");
        setReportid("");
        setEndDate("");
        setStartdate("")
        setError(false);
        onReset();
        // setcoveredEntityid('');
         //configuration.coveredEntity = {};
        updateStates(reports);
        resetStates('reports');
    };

    return (

        <div>
            <Formik
                initialValues={searchTerms}
                onSubmit={handleSubmit}
                onReset={handleReset}
                // validationSchema={validationSchema}
                enableReinitialize={true}
                children={(props) => {
                    const { isSubmitting, handleSubmit, resetForm, setFieldValue, errors, touched } = props;
                    return (
                        <div>
                            <div >
                                <Row className='d-flex' style={{}}>
                                    <Col md={3} lg={3} sm={6} xs={6}  >
                                        {' '}

                                        <FormGroup className='controls'>

                                            <Label
                                                htmlFor="Application" for='reportname'
                                                className={focusedInput === 'Application' ? 'label-focus' : ''}
                                            >Application*</Label>
                                            <Field
                                                id='reportname'
                                                name='reportname'
                                                type='select'
                                                placeholder="(All)"
                                                autoComplete='off'
                                                value={appid}
                                                disabled
                                                onChange={(e) => handleAppChange(e)}
                                                component={FormikSelectField}
                                                options={application}
                                                defaultoption={application[0].applicationName}
                                                keys={{
                                                    id: 'applicationid',
                                                    label: 'applicationName',
                                                }}
                                                onFocus={() => handleFocus('Application')}
                                                onBlur={handleBlur}
                                            >
                                            </Field>
                                            {(error) && (appid == '' || appid === undefined) && <div className="text-danger f-12">
                                                Application Type is required </div>}
                                        </FormGroup>
                                    </Col>
                                    <Col md={3} lg={3} sm={6} xs={6}>
                                        {' '}
                                        <FormGroup>
                                            <Label for='coveredentity'></Label>
                                            <Label
                                                htmlFor="CoveredEntity" for='reportname'
                                                className={focusedInput === 'CoveredEntity' ? 'label-focus' : ''}
                                            >Covered Entity /OPAID *</Label>
                                            {/* <ReactSelect
                                                name='coveredentity'
                                                id='coveredentity'
                                                options={[]}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                optionSelected={true}
                                                onFocus={() => handleFocus('CoveredEntity')}
                                                onBlur={handleBlur}

                                                // components={{
                                                //     Option, ValueContainer: LimitedChipsContainer
                                                // }}
                                                isSearchable="true"
                                                // onChange={(value, cp) =>
                                                //     handleChainPharmacyChange(value, setFieldValue, cp)}
                                                // value={selectedOption.optionSelected}
                                                className='claim_info'
                                                maxMenuHeight={220}
                                                styles={{
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        zIndex: 99,
                                                    }),
                                                }}
                                            /> */}
                                            <Field
                                                name='covereEntity'
                                                type='text'
                                                value={ceid}
                                                onChange={(e) => handleCeChange(e)}
                                                component={FormikSelectField}
                                                options={coveredEntityData}
                                                onFocus={() => handleFocus('CoveredEntity')}
                                                onBlur={handleBlur}
                                                defaultoption='--select--'
                                                keys={{
                                                    id: 'ceid',
                                                    label: 'ceName',
                                                }}
                                            />
                                            {(error) && (ceid == '' || ceid === undefined) && <div className="text-danger f-12">
                                                Covered Entity name is required</div>}
                                        </FormGroup>
                                    </Col>



                                    <Col md={3} lg={3} sm={6} xs={6}  >
                                        {' '}
                                        <FormGroup>

                                            <Label
                                                htmlFor="ReportType" for='reportname'
                                                className={focusedInput === 'ReportType' ? 'label-focus' : ''}
                                            >Report Type</Label>
                                            <Field
                                                name='ReportType'
                                                type='text'
                                                value={reportid}
                                                onChange={handleReportChange}
                                                component={FormikSelectField}
                                                options={reportType}
                                                onFocus={() => handleFocus('ReportType')}
                                                onBlur={handleBlur}
                                                defaultoption='--select--'
                                                keys={{
                                                    id: 'reportTypeID',
                                                    label: 'reportTypeName',
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3} >
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label></Label>
                                                    <Label
                                                        htmlFor="StartDate" for='reportname'
                                                        className={focusedInput === 'StartDate' ? 'label-focus' : ''}
                                                    >Start Date</Label>
                                                    <InputGroup onMouseLeave={handleMouseLeave}>
                                                        <Field
                                                            setcss="required"
                                                            type='text'
                                                            id='StartDate'
                                                            name='StartDate'
                                                            autoComplete='off'
                                                            placeholder='Select a date'
                                                            value={startDateValue}
                                                            component={FormikDatePicker}
                                                            onChange={handleStartDate}
                                                            onFocus={() => handleFocus('StartDate')}
                                                            onBlur={handleBlur}
                                                        />
                                                        <InputGroupText
                                                            onClick={() => handleDateClick('StartDate')}
                                                        >
                                                            <img src={Calendar} alt='' />
                                                        </InputGroupText>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col xs='auto' className='pt-3 mt-3'>
                                                <Label className='mt-1'>-</Label>
                                            </Col>
                                            <Col>
                                                <FormGroup>

                                                    <Label
                                                        htmlFor="EndDate" for='reportname'
                                                        className={focusedInput === 'EndDate' ? 'label-focus' : ''}
                                                    >End Date</Label>
                                                    <InputGroup onMouseLeave={handleMouseLeave}>
                                                        <Field
                                                            setcss="required"
                                                            type='text'
                                                            id='EndDate'
                                                            name='EndDate'
                                                            autoComplete='off'
                                                            placeholder='Select a date'
                                                            value={endDateValue}
                                                            component={FormikDatePicker}
                                                            onChange={handleEndDate}
                                                            onFocus={() => handleFocus('EndDate')}
                                                            onBlur={handleBlur}
                                                        />
                                                        <InputGroupText
                                                            onClick={() => handleDateClick('EndDate')}
                                                        >
                                                            <img src={Calendar} alt='' />
                                                        </InputGroupText>
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                    </Col>



                                </Row>
                            </div>
                            <div className='section-devider-border mt-2 mb-3'></div>
                            <div hidden={""}>
                                <Row>
                                    <Col sm={0} className="d-flex" style={{ justifyContent: "flex-end" }}>
                                        <Label>{""}</Label>
                                        <Button
                                            // hidden={isAddPermission}
                                            // disabled={isSubmitting}
                                            color="primary"
                                            className="reset"
                                            onClick={handleReset}
                                            // onClick={""}

                                        // disabled={!coveredEntityid}
                                        // hidden={!hasPermission("ADD")}
                                        >
                                            <span className='right_5'><img src={reset} alt="" /></span>
                                            Reset
                                        </Button>
                                        <Button
                                            // hidden={isAddPermission}
                                            // disabled={isSubmitting}
                                            color="primary"
                                            className="search"
                                            onClick={handleSubmit}

                                        // disabled={!coveredEntityid}
                                        // hidden={!hasPermission("ADD")}
                                        >
                                            <span className='right_5'> <img src={search} alt="" /></span>
                                            Search
                                        </Button>
                                    </Col>
                                </Row>
                            </div>











                        </div>);
                }}>

            </Formik>



        </div>
    )
}

export default SearchContainer