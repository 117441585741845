import React from 'react';
import moment from 'moment';

export const cellRendererCheck = (value) => {
  if (!value) return '-';
  return value;
};
let listauto = [];

export const cellRendererToolTip = (row) => {
  if (!row.faxStatus) return '-';
  return (<span title={row.faxStatusToolTip}>{row.faxStatus}</span>);
}

export const currencyFormat = (value) => {
  if (!value || isNaN(value)) return '-';
  if (value < 0)
    return (
      <span className='text-danger text-end'>
        {'-$' +
          (value * -1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
      </span>
    );
  else
    return (
      <span className='text-end'>
        {'$' + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
      </span>
    );
};

export const fileFormatter = (row, download) => {
  const { files } = row;
  if (files && files.length) {
    return files.map((file, index) => {
      return (
        <span
          className='fax-link'
          key={`${file.fileName}${index}`}
          onClick={() => download(file)}
        >
          {file.fileName}
          <br />
        </span>
      );
    });
  }
  return <span>There is no attachment</span>;
};

export const dateFormate = (value) => {
  if (!value) return '-';
  return moment(new Date(value)).format('MM/DD/YYYY');
};

const commonColumns = [
  {
    dataField: 'patientFirstName',
    text: 'Patient First Name',
    cellRenderer: (rowData) => cellRendererCheck(rowData.patientFirstName),
    sort: true,
    width: 144,
    show: true,
    inputType: 'text',
  },
  {
    dataField: 'patientLastName',
    text: 'Patient Last Name',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.patientLastName),
  },
  {
    dataField: 'patientDOB',
    text: 'Patient DOB',
    sort: true,
    width: 140,
    show: true,
    inputType: 'date',
    cellRenderer: (rowData) => dateFormate(rowData.patientDOB),
  },
  {
    dataField: 'medicalRecordNumber',
    text: 'Medical Record Number',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.medicalRecordNumber),
  },
  {
    dataField: 'claimStatus',
    text: 'Claim Status',
    sort: false,
    cellRenderer: (rowData) => cellRendererCheck(rowData.claimStatus),
    width: 130,
    show: true,
    edit: true,
    inputType: 'text',
    hideColFilter: true,
  },
  {
    dataField: 'reasonDesc',
    text: 'Reason',
    sort: false,
    cellRenderer: (rowData) => cellRendererCheck(rowData.reasonDesc),
    width: 150,
    show: true,
    edit: true,
    inputType: 'text',
    hideColFilter: true,
  },
  {
    dataField: 'rxNumber',
    text: 'RX Number',
    sort: true,
    width: 90,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.rxNumber),
  },
  {
    dataField: 'refillNumber',
    text: 'Refill Number',
    sort: true,
    width: 90,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.refillNumber),
  },
  {
    dataField: 'dateRXWritten',
    text: 'Date Rx Written',
    cellRenderer: (rowData) => dateFormate(rowData.dateRXWritten),
    sort: true,
    width: 120,
    show: true,
    inputType: 'date',
  },
  {
    dataField: 'dateofService',
    text: 'Date of Service',
    cellRenderer: (rowData) => dateFormate(rowData.dateofService),
    sort: true,
    width: 120,
    show: true,
    inputType: 'date',
  },
  {
    dataField: 'ndc',
    text: 'NDC',
    sort: true,
    width: 110,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.ndc),
  },
  {
    dataField: 'drugName',
    text: 'Drug Name',
    sort: true,
    width: 100,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.drugName),
  },
  {
    dataField: 'qtyDispensed',
    text: 'Qty Dispensed',
    sort: true,
    width: 100,
    show: true,
    inputType: 'number',
    cellRenderer: (rowData) => cellRendererCheck(rowData.qtyDispensed),
  },
  {
    dataField: 'daysSupply',
    text: 'Days Supply',
    sort: true,
    width: 100,
    show: true,
    inputType: 'number',
    cellRenderer: (rowData) => cellRendererCheck(rowData.daysSupply),
  },
  {
    dataField: 'gcnSequenceNumber',
    text: 'GCN Sequence Number',
    sort: true,
    width: 150,
    show: true,
    inputType: 'number',
    cellRenderer: (rowData) => cellRendererCheck(rowData.gcnSequenceNumber),
  },
  {
    dataField: 'manufacturerName',
    text: 'Manufacturer Name',
    sort: true,
    width: 120,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.manufacturerName),
  },
  {
    dataField: 'drugStatusDesc',
    text: 'Drug Status Desc',
    sort: true,
    width: 120,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.drugStatusDesc),
  },
  {
    dataField: 'claimType',
    text: 'Claim Type',
    sort: true,
    width: 120,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.claimType),
  },
  {
    dataField: 'claimSubType',
    text: 'Claim Sub Type',
    sort: true,
    width: 120,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.claimSubType),
  },
  {
    dataField: 'sourceReason',
    text: 'Source Reason',
    sort: true,
    width: 120,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.sourceReason),
  },
  {
    dataField: 'totalPaid',
    text: 'Total Paid',
    sort: true,
    width: 125,
    show: true,
    inputType: 'number',
    cellRenderer: (rowData) => currencyFormat(rowData.totalPaid),
    className: 'text-end',
  },
  {
    dataField: 'dispensingFee',
    text: 'Dispensing Fee',
    sort: true,
    width: 125,
    show: true,
    inputType: 'number',
    cellRenderer: (rowData) => currencyFormat(rowData.dispensingFee),
    className: 'text-end',
  },
  {
    dataField: 'ceReceivable',
    text: 'CE Receivable',
    sort: true,
    width: 125,
    show: true,
    inputType: 'number',
    cellRenderer: (rowData) => currencyFormat(rowData.ceReceivable),
    className: 'text-end',
  },
  {
    dataField: 'drugCost340B',
    text: 'Drug Cost 340B',
    sort: true,
    width: 125,
    show: true,
    inputType: 'number',
    cellRenderer: (rowData) => currencyFormat(rowData.drugCost340B),
    className: 'text-end',
  },
  {
    dataField: 'profitLoss',
    text: 'Profit/Loss',
    sort: true,
    width: 125,
    textAlign: 'right',
    show: true,
    inputType: 'number',
    cellRenderer: (rowData) => currencyFormat(rowData.profitLoss),
    className: 'text-end',
  },
  {
    dataField: "rcsFeeAmt",
    text: "RCS Fee Amount",
    cellRenderer: (rowData) => currencyFormat(rowData.rcsFeeAmt),
    sort: true,
    width: 144,
    show: true,
    inputType: 'number',
    className: 'text-end',
  },
  {
    dataField: 'hasVisit',
    text: 'Eligible Visit',
    sort: false,
    width: 130,
    show: true,
    inputType: 'text',

    cellRenderer: (rowData) => cellRendererCheck(rowData.hasVisit),
    hideColFilter: true,
  },
  {
    dataField: 'providerNPI',
    text: 'Provider NPI',
    sort: true,
    width: 120,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.providerNPI),
  },
  {
    dataField: 'providerFirstName',
    text: 'Provider First Name',
    sort: false,
    width: 130,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (rowData) => cellRendererCheck(rowData.providerFirstName),
  },
  {
    dataField: 'providerLastName',
    text: 'Provider Last Name',
    sort: false,
    width: 130,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (rowData) => cellRendererCheck(rowData.providerLastName),
  },
  {
    dataField: 'providerSpecialty',
    text: 'Provider Specialty',
    sort: false,
    width: 130,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.providerSpecialty),
    hideColFilter: true,
  },
  {
    dataField: 'providerStatus',
    text: 'Provider Status',
    sort: false,
    width: 130,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.providerStatus),
    hideColFilter: true,
  },
  {
    dataField: 'faxNumber',
    text: 'Fax Number',
    sort: true,
    width: 130,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.faxNumber),
  },
  {
    dataField: 'phoneNumber',
    text: 'Phone Number',
    sort: true,
    width: 130,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.phoneNumber),
  },
  {
    dataField: 'faxCount',
    text: 'Fax Count',
    sort: true,
    width: 130,
    show: true,
    inputType: 'number',
    hideColFilter: true,
    cellRenderer: (rowData) => cellRendererCheck(rowData.faxCount),
  },
  {
    dataField: 'pharmacyNPI',
    text: 'Pharmacy NPI',
    sort: true,
    width: 130,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.pharmacyNPI),
  },
  {
    dataField: 'pharmacyName',
    text: 'Pharmacy Name',
    sort: false,
    width: 130,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (rowData) => cellRendererCheck(rowData.pharmacyName),
  },
  {
    dataField: 'thirdPartyAdminID',
    text: 'Third Party Admin',
    sort: false,
    width: 130,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (rowData) => cellRendererCheck(rowData.thirdPartyAdminID),
  },
  {
    dataField: 'bin',
    text: 'BIN',
    sort: true,
    width: 130,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.bin),
  },
  {
    dataField: 'pcn',
    text: 'PCN',
    sort: true,
    width: 130,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.pcn),
  },
  {
    dataField: 'groupNumber',
    text: 'Group Number',
    sort: true,
    width: 130,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.groupNumber),
  },
  {
    dataField: 'secondaryBIN',
    text: 'Secondary BIN',
    sort: true,
    width: 130,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.secondaryBIN),
  },
  {
    dataField: 'secondaryPCN',
    text: 'Secondary PCN',
    sort: true,
    width: 130,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.secondaryPCN),
  },
  {
    dataField: 'secondaryGroup',
    text: 'Secondary Group',
    sort: true,
    width: 130,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.secondaryGroup),
  },

  {
    dataField: 'createdDate',
    text: 'Created Date',
    sort: true,
    width: 125,
    show: true,
    inputType: 'date',
    cellRenderer: (rowData) => dateFormate(rowData.createdDate),
  },
  {
    dataField: 'createdBy',
    text: 'Created By',
    sort: false,
    width: 125,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.createdBy),
    hideColFilter: true,
  },
  {
    dataField: 'claimStatusChangeDate',
    text: 'Status Change Date',
    sort: false,
    width: 125,
    show: true,
    hideColFilter: true,
    inputType: 'date',
    cellRenderer: (rowData) => dateFormate(rowData.claimStatusChangeDate),
  },
  {
    dataField: 'statusChangedBy',
    text: 'Status Changed By',
    sort: false,
    width: 125,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.statusChangedBy),
    hideColFilter: true,
  },
  {
    dataField: 'completedDate',
    text: 'Completed Date',
    sort: true,
    width: 125,
    show: true,
    inputType: 'date',
    cellRenderer: (rowData) => dateFormate(rowData.completedDate),
  },
  {
    dataField: 'completedBy',
    text: 'Completed By',
    sort: false,
    width: 125,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.completedBy),
    hideColFilter: true,
  },
  {
    dataField: 'invoicedDate',
    text: 'Invoiced Date',
    sort: true,
    width: 125,
    show: true,
    inputType: 'date',
    cellRenderer: (rowData) => dateFormate(rowData.invoicedDate),
  },
  {
    dataField: 'invoicedBy',
    text: 'Invoiced By',
    sort: false,
    width: 125,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.invoicedBy),
    hideColFilter: true,
  },
  {
    dataField: 'modifiedDate',
    text: 'Modified Date',
    sort: true,
    width: 125,
    show: true,
    inputType: 'date',
    cellRenderer: (rowData) => dateFormate(rowData.modifiedDate),
  },
  {
    dataField: 'modifiedBy',
    text: 'Modified By',
    sort: false,
    width: 125,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.modifiedBy),
    hideColFilter: true,
  },
  {
    dataField: 'claimID',
    text: 'Claim ID',
    sort: true,
    width: 125,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.claimID),

  },
  {
    dataField: 'sourceClaimID',
    text: 'Source Claim ID',
    sort: true,
    width: 125,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.sourceClaimID),

  },
  {
    dataField: 'claimReferenceID',
    text: 'Claim Reference ID',
    sort: true,
    width: 130,
    show: true,
    inputType: 'text',
    cellRenderer: (rowData) => cellRendererCheck(rowData.claimReferenceID),

  },
];

export default commonColumns;

export const faxColumns = [
  {
    dataField: 'patientFirstName',
    text: 'Patient First Name',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.patientFirstName),
  },
  {
    dataField: 'patientLastName',
    text: 'Patient Last Name',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.patientLastName),
  },
  {
    dataField: 'patientDOB',
    text: 'Patient DOB',
    sort: true,
    width: 140,
    show: true,
    inputType: 'date',
    hideColFilter: true,
    cellRenderer: (row) => dateFormate(row.patientDOB),
  },
  {
    dataField: 'providerNPI',
    text: 'Provider NPI',
    sort: true,
    width: 120,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.providerNPI),
  },
  {
    dataField: 'providerFirstName',
    text: 'Provider First Name',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.providerFirstName),
  },
  {
    dataField: 'providerLastName',
    text: 'Provider Last Name',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.providerLastName),
  },
  {
    dataField: 'fromFaxNumber',
    text: 'From Fax Number',
    sort: true,
    width: 120,
    show: true,
    inputType: 'text',
    cellRenderer: (row) => cellRendererCheck(row.fromFaxNumber),
  },
  {
    dataField: 'toFaxNumber',
    text: 'To Fax Number',
    sort: true,
    width: 100,
    show: true,
    inputType: 'text',
    cellRenderer: (row) => cellRendererCheck(row.toFaxNumber),
  },
  {
    dataField: 'direction',
    text: 'Direction',
    sort: true,
    width: 90,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.direction),
  },
  {
    dataField: 'faxStatus',
    text: 'Fax Status',
    sort: true,
    width: 110,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererToolTip(row),
  },
  {
    dataField: 'retryCount',
    text: 'Retry Count',
    sort: true,
    width: 90,
    show: true,
    inputType: 'number',
    cellRenderer: (row) => cellRendererCheck(row.retryCount),
  },
  {
    dataField: 'comments',
    text: 'Comments',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.comments),
  },
  {
    dataField: 'forwardCEComments',
    text: 'Forwarded CE Comments',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.forwardCEComments),
  },
  {
    dataField: 'createdBy',
    text: 'Created By',
    sort: false,
    width: 125,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.createdBy),
  },
  {
    dataField: 'createdDate',
    text: 'Created Date',
    sort: true,
    width: 125,
    show: true,
    inputType: 'date',
    hideColFilter: true,
    cellRenderer: (row) => dateFormate(row.createdDate),
  },
  {
    dataField: 'modifiedBy',
    text: 'Modified By',
    sort: false,
    width: 125,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.modifiedBy),
  },
  {
    dataField: 'modifiedDate',
    text: 'Modified Date',
    sort: true,
    width: 125,
    show: true,
    inputType: 'date',
    hideColFilter: true,
    cellRenderer: (row) => dateFormate(row.modifiedDate),
  },
  {
    dataField: 'apiFaxStatus',
    text: 'API Fax Status',
    sort: true,
    width: 120,
    show: true,
    inputType: 'text',
    cellRenderer: (row) => cellRendererCheck(row.apiFaxStatus),
  },
  {
    dataField: 'apiPageCount',
    text: 'API Page Count',
    sort: true,
    width: 120,
    show: true,
    inputType: 'number',
    cellRenderer: (row) => cellRendererCheck(row.apiPageCount),
  },
  {
    dataField: 'apiPageComplete',
    text: 'API Page Complete',
    sort: true,
    width: 110,
    show: true,
    inputType: 'number',
    cellRenderer: (row) => cellRendererCheck(row.apiPageComplete),
  },
  {
    dataField: 'apiFaxDirection',
    text: 'API Fax Direction',
    sort: true,
    width: 110,
    show: true,
    inputType: 'text',
    cellRenderer: (row) => cellRendererCheck(row.apiFaxDirection),
  },
  {
    dataField: 'apiFaxCreatedAt',
    text: 'API Fax Created At',
    sort: true,
    width: 150,
    show: true,
    inputType: 'date',
    cellRenderer: (row) => dateFormate(row.apiFaxCreatedAt),
  },
  {
    dataField: 'validUpto',
    text: 'Valid Upto',
    sort: true,
    width: 150,
    show: true,
    inputType: 'date',
    hideColFilter: true,
    cellRenderer: (row) => dateFormate(row.validUpto)
  },
  {
    dataField: 'apiForwardFaxStatus',
    text: 'API Forward Fax Status',
    sort: true,
    width: 110,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.apiForwardFaxStatus),
  },
  {
    dataField: 'forwardFaxNumber',
    text: 'Forward Fax Number',
    sort: true,
    width: 110,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.forwardFaxNumber),
  }
];


export const invoiceColumns = [
  {
    dataField: 'invoiceNumber',
    text: 'Invoice Number',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.invoiceNumber),
  },
  {
    dataField: 'createdDate',
    text: 'Generated On',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => dateFormate(row.createdDate),
  },
  {
    dataField: 'invoiceDateRange',
    text: 'Date Range',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.invoiceDateRange),
  },
  {
    dataField: 'invoiceStatus',
    text: 'Invoice Status',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.invoiceStatus),
  },
  {
    dataField: 'invoiceCycle',
    text: 'Invoice Cycle',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.invoiceCycle),
  },
  {
    dataField: 'rxCount',
    text: 'Rx Count',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.rxCount),
  },
  {
    dataField: 'totalPaid',
    text: 'Total Paid',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => currencyFormat(row.totalPaid),
  },
  {
    dataField: 'totalDispensingFee',
    text: 'Dispensing Fee',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => currencyFormat(row.totalDispensingFee),
  },
  {
    dataField: 'totalCEReceivable',
    text: 'CE Receivable',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => currencyFormat(row.totalCEReceivable),
  },
  {
    dataField: 'totalDrugCost340B',
    text: 'Drug Cost 340B',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => currencyFormat(row.totalDrugCost340B),
  },
  {
    dataField: 'totalProfitLoss',
    text: 'Profit & Loss',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => currencyFormat(row.totalProfitLoss),
  },
  {
    dataField: 'totalRcsFeeAmount',
    text: 'RCS Fee',
    sort: true,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => currencyFormat(row.totalRcsFeeAmount),
  }
]

export const invoiceDetailColumns = [
  {
    dataField: 'prescriptionNumber',
    text: 'Prescription Number',
    sort: false,
    width: 160,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.prescriptionNumber),
  },
  {
    dataField: 'refillNumber',
    text: 'Refill Number',
    sort: false,
    width: 120,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.refillNumber),
  },
  {
    dataField: 'dateRxWritten',
    text: 'Date Rx Written',
    sort: false,
    width: 140,
    show: true,
    inputType: 'date',
    hideColFilter: true,
    cellRenderer: (row) => dateFormate(row.dateRxWritten),
  },
  {
    dataField: 'fillDate',
    text: 'Fill Date',
    sort: false,
    width: 140,
    show: true,
    inputType: 'date',
    hideColFilter: true,
    cellRenderer: (row) => dateFormate(row.fillDate),
  },
  {
    dataField: 'ndc',
    text: 'NDC',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.ndc),
  },
  {
    dataField: 'drugName',
    text: 'Drug Name',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.drugName),
  },
  {
    dataField: 'manufacturerName',
    text: 'Manufacturer Name',
    sort: false,
    width: 160,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.manufacturerName),
  },
  {
    dataField: 'qtyDispensed',
    text: 'Qty Dispensed',
    sort: false,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.qtyDispensed),
  },
  {
    dataField: 'totalPayment',
    text: 'Total Payment',
    sort: false,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: true,
    cellRenderer: (row) => currencyFormat(row.totalPayment),
    edit: true,
  },
  {
    dataField: 'dispFee',
    text: 'Dispensing Fee',
    sort: false,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: true,
    cellRenderer: (row) => currencyFormat(row.dispFee),
    edit: true,
  },
  {
    dataField: 'ceReceivable',
    text: 'CE Receivable',
    sort: false,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: true,
    cellRenderer: (row) => currencyFormat(row.ceReceivable),
    edit: true,
  },
  {
    dataField: 'drug340BCost',
    text: 'Drug 340B Cost',
    sort: false,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: true,
    cellRenderer: (row) => currencyFormat(row.drug340BCost),
    edit: true,
  },
  {
    dataField: 'netProfit',
    text: 'Net Profit',
    sort: false,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: true,
    cellRenderer: (row) => currencyFormat(row.netProfit),
    edit: true,
  },
  {
    dataField: 'rcsFee',
    text: 'RCS Fee',
    sort: false,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: true,
    cellRenderer: (row) => currencyFormat(row.rcsFee),
    edit: true,
  },
  {
    dataField: 'pharmacy',
    text: 'Pharmacy',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.pharmacy),
  },
  {
    dataField: 'pharmacyNABPorNPI',
    text: 'Pharmacy NABP or NPI',
    sort: false,
    width: 160,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.pharmacyNABPorNPI),
  },
  {
    dataField: 'daysSupply',
    text: 'Days Supply',
    sort: false,
    width: 140,
    show: true,
    inputType: 'number',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.daysSupply),
  },
  {
    dataField: 'bin',
    text: 'BIN',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.bin),
  },
  {
    dataField: 'pcn',
    text: 'PCN',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.pcn),
  },
  {
    dataField: 'group',
    text: 'Group',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.group),
  },
  {
    dataField: 'rcmApproveDate',
    text: 'RCM Approve Date',
    sort: false,
    width: 140,
    show: true,
    inputType: 'date',
    hideColFilter: true,
    cellRenderer: (row) => dateFormate(row.rcmApproveDate),
  },
  {
    dataField: 'rcmReplenishmentDate',
    text: 'RCM Replenishment Date',
    sort: false,
    width: 180,
    show: true,
    inputType: 'date',
    hideColFilter: true,
    cellRenderer: (row) => dateFormate(row.rcmReplenishmentDate),
  },
  {
    dataField: 'providerNPI',
    text: 'Provider NPI',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.providerNPI),
  },
  {
    dataField: 'providerFirstName',
    text: 'Provider First Name',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.providerFirstName),
  },
  {
    dataField: 'providerLastName',
    text: 'Provider Last Name',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.providerLastName),
  },
  {
    dataField: 'claimType',
    text: 'Claim Type',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.claimType),
  },
  {
    dataField: 'reason',
    text: 'Reason',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.reason),
  },
  {
    dataField: 'claimID',
    text: 'Claim ID',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.claimID),
  },
  {
    dataField: 'poid',
    text: 'POID',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.poid),
  },
  {
    dataField: 'invoiceNumber',
    text: 'Invoice Number',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.invoiceNumber),
  },
];
export const patientProviderMapColumns = [
  {
    dataField: 'providerFirstName',
    text: 'Provider First Name',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.providerFirstName),
  },
  {
    dataField: 'providerLastName',
    text: 'Provider Last Name',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.providerLastName),
  },
  {
    dataField: 'providerNPI',
    text: 'Provider NPI',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.providerNPI),
  },
  {
    dataField: 'patientFirstName',
    text: 'Patient First Name',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.patientFirstName),
  },
  {
    dataField: 'patientLastName',
    text: 'Patient Last Name',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.patientLastName),
  },
  {
    dataField: 'endDate',
    text: 'Expire',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => dateFormate(row.endDate),
  },
  {
    dataField: 'modifiedDate',
    text: 'Modified Date',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => dateFormate(row.modifiedDate),
  },
  {
    dataField: 'modifiedBy',
    text: 'Modified By',
    sort: false,
    width: 140,
    show: true,
    inputType: 'text',
    hideColFilter: true,
    cellRenderer: (row) => cellRendererCheck(row.modifiedBy),
  }
];