import React, { useState, useEffect } from 'react'
import { Card, CardBody, Container, Row, Col, FormGroup, Label, Input, Button, ButtonGroup, InputGroup, InputGroupText } from 'reactstrap';
import ReactSelect from 'react-select';
import { Field, Formik, ErrorMessage } from 'formik';
import FormikDatePicker from 'components/common/Fields/FormikDatePicker';
import * as Yup from 'yup'
import { Option, LimitedChipsContainer } from 'components/Options';
import Calendar from 'assets/icon/calendar-icon.svg';
import useAxios from 'hooks/useAxios';
// import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import CoveredEntity from 'components/CoveredEntity';
import FormikSelectField from 'components/common/Fields/FormikSelectField';
import Loader from 'components/Loader';
//let ManufacturerData = [];
 let pharmacyData = [];

const AddConfigurations = ( 
  {manvalPharacy,
     onSearch,
      Loading,
      onSave
    } 
    ) => {

  const API = useAxios()

  const steps = [
    { label: '1', content: 'Report Details' },
    { label: '2', content: 'Report Configuration' },
    { label: '3', content: 'Report Settings' },
  ];
  const application = [
    { applicationid: 101, applicationName: 'Contract Pharmacy' }
  ]

  const ClaimTypes = [
    { claimTypeid: 'A', claimTypeName: 'All' },
    { claimTypeid: 'C', claimTypeName: 'Captured' },
    { claimTypeid: 'NC', claimTypeName: 'Non-Captured' }

  ]

  const [currentStep, setCurrentStep] = useState(1);
  const [startDate, setStartdate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [state, setState] = useState(true);
  const [selectedManufactureOption, setmanufactureOptionSelected] = useState({ optionMfSelected: '-1' });
  const [selectedpharmacyOption, setPharmacyOption] = useState({ optionPhSelected: '-1' });
  const [coveredEntity, setCoveredEntity] = useState([])
  const [ceid, setceid] = useState()
  const [appid, setAppid] = useState(application[0].applicationid)
  const [reportType, setReportType] = useState([])
  const [reportid, setReportid] = useState('')
  const [reportName, setReportName] = useState('')
  const [claimid, setClaimid] = useState('')
  const [clientId, setClientId] = useState([])
  const [comments, setComments] = useState('')
  const [isChecked, setIsChecked] = useState(false);
  const [frequency, setFrequency] = useState('')
  const [ManufactureIds, setManufactureIds] = useState([])
  const [loading, setLoading] = useState(false);
  const [error, setError]= useState(false);
  const [ManufacturerData, setManufactureData]= useState([])
  // const[pharmacyData,setPharmacyData]=useState([])

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };
  const Autoselect = isChecked ? 'Y' : 'N'


  let hasDatePopupOpen = false;

  // const nextStep = () => {
  //   if (currentStep < steps.length - 1) {
  //     setCurrentStep(currentStep + 1);
  //   }
  // };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };


  const handelCancel = () => {
    setShowDetails(true)
  }
  const handleMouseLeave = () => {
    hasDatePopupOpen = false;
  };

  const handleStartDate = (startDate) => {
      if(startDate){
    setStartdate(new Date(startDate))}
    else{
      setStartdate('')
      setError(true)
    }
  };

  const handleDateClick = (id) => {
    if (hasDatePopupOpen) {
      hasDatePopupOpen = false;
      return;
    }
    const calendarButton = document.getElementById(id);
    if (calendarButton) {
      calendarButton.click();
      hasDatePopupOpen = true;
    }
  };

  const handleEndDate = (endDate) => {
    if(endDate){
    setEndDate(new Date(endDate))
    }
    else{
      setEndDate('')
    }
  };

  const onFrequencyChange = (value) => {
    if(value){
    setFrequency(value)
    }
  }




  const numSteps = 3;

  const nextStep = () => {
    if(currentStep === 1){
      if( reportName == '' || reportid=='' || reportid== 'undefined' || appid=='' || appid == undefined || ceid== '' || ceid== undefined){
        setError(true)
      }
    else{
      setError(false)
      setCurrentStep((prevStep) => (prevStep < numSteps && prevStep + 1));
    } 
  };
  if(currentStep ===2){
    if(frequency=='' || frequency==undefined || startDate=='' || startDate == undefined || ManufactureIds == '' || ManufactureIds==undefined
      || clientId=='' || clientId== undefined || claimid=='' || claimid== 'undefined'){
      setError(true)
    }
  else{
    setError(false)
    setCurrentStep((prevStep) => (prevStep < numSteps && prevStep + 1));
  } 
  }
}

  const handleDiscard = () => {
    if (currentStep === 1) {
      manvalPharacy(); 
    }
    else{
    setCurrentStep(currentStep - 1);
    }
  };

  const [focusedInput, setFocusedInput] = useState('');

  const handleFocus = (inputName) => {
    setFocusedInput(inputName);
  };

  const handleBlur = () => {
    setFocusedInput('');
  };

  const handleComments = (e) => {
    setComments(e.target.value)
  }

  const handelNetClaim = () => {
    setState(!state)
  }
  const netClaimValue = state ? 'Y' : 'N'


  useEffect(() => {
    fetchManufacturer();
    fetchCoveredEntities();
    // fetchPharmacy();
    // fetchPharmacy();
  }, [])

  useEffect(()=>{
    fetchReportType();
  },[appid])

  const fetchManufacturer = async () => {
    setLoading(true)
    try {
      const { data } = await API.get('api/CP/manufacturers'
          , {
            hasDefaultURL: true,
        }
      );
      setLoading(false)
      // console.log(data,"data")
      // let val = data
      //     if (Array.isArray(val)) {
      //         setManufacture(val);
      if (data) {
        for (var x in data) {
          ManufacturerData.push({ "value": data[x].manufacturerId, "label": data[x].manufacturerName });
        }
      }
      else{
        setManufactureData([])
      }
    } catch (error) {
      'failed to load data'
    }
  };

  const onManufactureSelect = (selected) => {
    if (selected) {
      let selectedvalue = selected.map(val => val['value'])
      setmanufactureOptionSelected({
        optionMfSelected: selected
      })
      
      let manufacturesids = []
      manufacturesids.push(selectedvalue)
      setManufactureIds(manufacturesids)
      // let val = selected.value;
      // setFieldValue(manufacturerId, val);
      // setFieldValue(manufacturerIds, selected);
    }
  }
  

  const fetchCoveredEntities = async () => {
    setLoading(true)
    try {
      const { data } = await API.get('api/CP'
          , {
            hasDefaultURL: true,
        }
      );
      if (data) {
        setCoveredEntity(data)
        setLoading(false)
      }
    } catch (error) {
      'failed to load data'
      setLoading(false)
    }
  };

  const coveredname = coveredEntity.find((item) => item.ceid == ceid)
  const CEname = coveredname ? coveredname.ceName : '';
  const OPAcode = coveredname ? coveredname.opaCode : ''

  const handleAppChange = (e) => {
    const { value } = e.target;
    if(value){
      setAppid(parseInt(value))
    fetchReportType(value)
    }
    else{
      setAppid('')
      setReportid('')
      setReportType([]) 
    }
  } 

  const fetchReportType = async () => {
    setLoading(true)
    try {
      const { data } = await API.get(`api/Report/types/${appid}`
          , {
            hasDefaultURL: true,
        }
      );
      setLoading(false)
    if(data){
      setReportType(data)
    }

    } catch (error) {
      'failed to load data'
      setLoading(false)
    }
  };

  const handleCeChange = (e) => {
    pharmacyData=[]

    const { value } = e.target;
    if(value === ''){
      setError(true)
      setceid('')
    }
    else{
    setceid(parseInt(value));
    fetchPharmacy(value);
    }
  };

  const fetchPharmacy = async (ceid) => {
    try {
      const { data } = await API.get(`api/CP/pharmacies/${ceid}`
        , {
          hasDefaultURL: true,
      }
      )

      if (data) {
        for (var x in data) {
           pharmacyData.push({ "value": data[x].clientID, "label": data[x].phName });
          // setPharmacyData({ "value": data[x].clientID, "label": data[x].phName })
        }
      }
    } catch (error) {
      'failed to load data'
    }
  };

  const onPharamcyChange = (selected) => {
    if (selected) {
      let selectedvalue = selected.map(val => val['value'])
      setPharmacyOption({
        optionPhSelected: selected
      });
      let clientids = []
      clientids.push(selectedvalue)
      setClientId(clientids)
 
    }
  }



  const handleReportChange = (e) => {
    const { value } = e.target;
    if(value){
    setReportid(parseInt(value));
    }
    else{
      setReportid('')
      setError(true)
    }
  }


  const handleReportName = (e) => {
    setReportName(e.target.value)
  }

  const handleClaimTypeChange = (e) => {
    const { value } = e.target
    if(value){
    setClaimid(value)
  }
  else{
    setClaimid('')
    setError(true)
  }
  }

  const handleResponse = (response) => {
    if (response.status === 200) {
      toast.success('Configured Report Added Successfully');
    } else {
      alert('Failed to add configurations');
    }
  };
  const fetchConfiguredCoveredEntities = async () => {
    setLoading(true)
    try {
      const { data } = await API.get('api/CP/configured'
          , {
            hasDefaultURL: true,
        }
      );
      setLoading(false)
      if (data) {
        setCoveredEntityData(data)
      }
    } catch (error) {
      'failed to load data'
      setLoading(false)
    }
  };
  const handleSave = async () => {
    let values = {};
    values.ApplicationId=appid,
    values.coveredEntityID=ceid
// const selectedEndDate= endDate.setUTCHours(0,0,0,0).toISOString()
// let setdate=startDate+1
let setDate = new Date(startDate);
setDate.setDate(startDate?.getDate() + 1);
let isoDate = setDate.toISOString();
let isoEndDate=""
if(endDate){
  let setendDate= new Date(endDate);
  setendDate.setDate(endDate.getDate()+1);
  isoEndDate= setendDate?.toISOString();
  
}


    var payload = {}
    payload.reportConfigID = 0,
    payload.ceid = parseInt(ceid),
    payload.ceName = CEname,
    payload.opaCode = OPAcode,
    payload.reportName = reportName,
    payload.reportTypeID = parseInt(reportid),
    payload.frequency = frequency,
    payload.claimType = claimid,
    payload.startDate = isoDate,
    payload.endDate = isoEndDate?isoEndDate:endDate,
    payload.netClaims = netClaimValue,
    payload.autoSend = Autoselect,
    payload.comments = comments,
    payload.mfgIDs = ManufactureIds[0],
    payload.clientIDs = clientId[0],
    payload.applicationID = parseInt(appid)

setLoading(true)
    try {
      const response = await API.post(`api/Report/add`, payload,
        {
          hasDefaultURL: true,
      })
      
      handleResponse(response);
setLoading(false)
onSave();
//fetchConfiguredCoveredEntities();

    }
    catch (error) {
      setLoading(false);
    }
    manvalPharacy();
   //await onSearch(values);
   setLoading(true)
  }


  return (
    <Formik
      children={(props) => {
        const { setFieldValue} = props;
        return (
          <div>
             {Loading&&<Loader opacity={0.01}color='black'/>} 
            <Card className='' style={{ height: "calc(100vh - 100px)", background: "transparent", border: "none" }}>
              <CardBody>

                <div>
                  <div className='step_info'>
                    <div className="stepper">
                      <div>
                        <div className="stepper-horizontal" id="stepper1">
                          {Array.from({ length: numSteps }, (_, index) => {
                            const stepNum = index + 1;
                            return (
                              <div
                                className={`step ${stepNum === currentStep ? 'editing fffff' : ''} ${stepNum < currentStep ? 'done' : ''}`}
                                key={stepNum}
                              >
                                <div className="step-circle">
                                  <span>{stepNum}</span>
                                </div>
                                <div className="step-title">
                                  {
                                    stepNum === 1 ? 'Report Details' :
                                      stepNum === 2 ? 'Report Configuration' :
                                        stepNum === 3 ? 'Report Settings' : ''
                                  }
                                </div>

                                {/* Only show content for the active step */}


                                <div className="step-bar-left"></div>
                                <div className="step-bar-right"></div>
                              </div>
                            );
                          })}
                        </div>
                        <div>
                          {currentStep && (
                            <div className="step-content">
                              {currentStep === 1 &&
                                <div>
                                  <div className="d-flex justify-content-between section-devider-border">
                                    <h3 className='font-20'>Report Details</h3>
                                  </div>
                                  <Row className='d-flex pt-3' style={{}}>
                                    <Col md={6} lg={6} sm={6} xs={6}  >
                                      {' '}
                                      <FormGroup>
                                        <Label
                                          htmlFor="application" for='reportname'
                                          className={focusedInput === 'application' ? 'label-focus' : ''}
                                        >Application *</Label>
                                        <Field
                                          name='Application'
                                          type='text'
                                         disabled
                                          value={appid}
                                          onChange={(e) => handleAppChange(e)}
                                          component={FormikSelectField}
                                          options={application}
                                          defaultoption={application[0].applicationName}
                                          keys={{
                                            id: 'applicationid',
                                            label: 'applicationName',
                                          }}
                                        />
                                          {(error) && (appid == '' || appid== undefined) && <div className="text-danger f-12">
                                            Application id is required </div>}
                                      </FormGroup>
                                    </Col>
                                    <Col md={6} lg={6} sm={6} xs={6}  >
                                      {' '}
                                      <FormGroup>

                                        <Label
                                          htmlFor="coveredentity" for='reportname'
                                          className={focusedInput === 'coveredentity' ? 'label-focus' : ''}
                                        >Covered Entity /OPAID *</Label>
                                        <Field
                                          name='covereEntity'
                                          type='text'

                                          value={ceid}
                                          onChange={(e) => handleCeChange(e)}
                                          component={FormikSelectField}
                                          options={coveredEntity}
                                          defaultoption='--select--'
                                          keys={{
                                            id: 'ceid',
                                            label: 'ceName',
                                          }}
                                        />
                                          {(error) && (ceid == '' || ceid== undefined) && <div className="text-danger f-12">
                                            Covered Entity name is required </div>}
                                      </FormGroup>
                                    </Col>

                                    <Col md={6} lg={6} sm={6} xs={6}  >
                                      {' '}
                                      <FormGroup>

                                        <Label
                                          htmlFor="ReportName" for='reportname'
                                          className={focusedInput === 'ReportName' ? 'label-focus' : ''}
                                        >Report Name *</Label>
                                        <Input
                                          className="iconremove"
                                          id="reportName"
                                          name="reportName"
                                          type="text"
                                          //className="required"
                                          placeholder="Enter Report name"
                                          autoComplete="off"
                                          value={reportName}
                                          onFocus={() => handleFocus('ReportName')}
                                          onBlur={handleBlur}
                                          //component={FormikInputField}
                                          onChange={handleReportName}
                                        // onChange={(e) => { handleInvoiceNumberOnChange(e, setFieldValue) }}
                                        />
                                         {(error) && (reportName == '' ) && <div className="text-danger f-12">
                                          Free Text is Required</div>}
                                      </FormGroup>
                                    </Col>

                                    <Col md={6} lg={6} sm={6} xs={6}  >
                                      {' '}
                                      <FormGroup>
                                        <Label for='reportname'></Label>
                                        <Label
                                          htmlFor="reporttype" for='reportname'
                                          className={focusedInput === 'reporttype' ? 'label-focus' : ''}
                                        >Report Type *</Label>
                                        <Field
                                          name='ReportType'
                                          type='text'
                                          value={reportid}
                                          onChange={ handleReportChange}
                                          component={FormikSelectField}
                                          options={reportType}
                                          onFocus={() => handleFocus('reporttype')}
                                          onBlur={handleBlur}
                                          defaultoption='--select--'
                                          keys={{
                                            id: 'reportTypeID',
                                            label: 'reportTypeName',
                                          }}
                                        />{(error) && (reportid == '' || reportid== undefined) && <div className="text-danger f-12">
                                            Report Type is required </div>}
                                         
                                      </FormGroup>
                                    </Col>

                                  </Row>
                                </div>
                              }
                              {currentStep === 2 &&
                                <div>
                                  <div className="d-flex justify-content-between section-devider-border">
                                    <h3 className='font-20'>Report Configurations</h3>
                                  </div>
                                  <div className='re_con_daily pt-3'>
                                    <Label for='' style={{ display: "block" }}>Frequency*</Label>
                                    <ButtonGroup aria-label="Basic example" className='mb-3'
                                     >
                                      <Button varient="primary" className={frequency === 'DL'? "active":''}
                                        onClick={() => onFrequencyChange('DL')}>Daily</Button>
                                      <Button varient="primary" className={frequency === 'WL'? "active":'' } 
                                       
                                        onClick={() => onFrequencyChange('WL')}>Weekly</Button>
                                      <Button varient="primary" className={frequency === 'BI'? "active":''} 
                                      
                                        onClick={() => onFrequencyChange('BI')}>Bi-Weekly</Button>
                                    </ButtonGroup>
                                    <div style={{marginTop:'-15px', marginBottom:'15px'}}>
                                    {(error) && (frequency == '' || frequency== undefined) && <div className="text-danger f-12">
                                      Frequency is required </div>}
                                      </div>
                                          <div>
                                            <div >
                                              <Row className='d-flex' style={{}}>


                                                <Col md={3} lg={6} sm={6} xs={6}>
                                                  <FormGroup>

                                                    <Label
                                                      htmlFor="StartDate" for='reportname'
                                                      className={focusedInput === 'StartDate' ? 'label-focus' : ''}
                                                    >Start Date*</Label>
                                                    <InputGroup onMouseLeave={handleMouseLeave}>
                                                      <Field
                                                        setcss="required"
                                                        type='text'
                                                        id='startDate'
                                                        name='startDate'
                                                        autoComplete='off'
                                                        placeholder='Select a date'
                                                        value={startDate? new Date(startDate):''}
                                                        component={FormikDatePicker}
                                                        onChange={handleStartDate}
                                                        onFocus={() => handleFocus('StartDate')}
                                                        onBlur={handleBlur}
                                                      />
                                                      <InputGroupText
                                                        onClick={() => handleDateClick('startDate')}
                                                      >
                                                        <img src={Calendar} alt='' />
                                                      </InputGroupText>
                                                    </InputGroup>
                                                    {(error) && (startDate == '' || startDate== undefined) && <div className="text-danger f-12">
                                                      Start Date is required </div>}
                                                  </FormGroup>
                                                </Col>

                                                <Col md={3} lg={6} sm={6} xs={6}>
                                                  <FormGroup>

                                                    <Label
                                                      htmlFor="EndDate" for='reportname'
                                                      className={focusedInput === 'EndDate' ? 'label-focus' : ''}
                                                    >End Date</Label>
                                                    <InputGroup onMouseLeave={handleMouseLeave}>
                                                      <Field
                                                        setcss="required"
                                                        type='text'
                                                        id='startDate'
                                                        name='startDate'
                                                        autoComplete='off'
                                                        placeholder='Select a date'
                                                        value={endDate}
                                                        component={FormikDatePicker}
                                                        onChange={handleEndDate}
                                                        onFocus={() => handleFocus('EndDate')}
                                                        onBlur={handleBlur}
                                                      />
                                                      <InputGroupText
                                                        onClick={() => handleDateClick('EndDate')}
                                                      >
                                                        <img src={Calendar} alt='' />
                                                      </InputGroupText>
                                                    </InputGroup>
                                                  </FormGroup>
                                                </Col>
                                                <Col md={3} lg={6} sm={6} xs={6}>
                                                  {' '}
                                                  <FormGroup>

                                                    <Label
                                                      htmlFor="Manufacturer" for='reportname'
                                                      className={focusedInput === 'Manufacturer' ? 'label-focus' : ''}
                                                    >Manufacturer*</Label>
                                                    <ReactSelect
                                                      name='coveredentity'
                                                      id='coveredentity'
                                                      options={ManufacturerData}
                                                      isMulti
                                                      closeMenuOnSelect={false}
                                                      hideSelectedOptions={false}
                                                      optionSelected={true}
                                                      onFocus={() => handleFocus('Manufacturer')}
                                                      onBlur={handleBlur}

                                                      components={{
                                                        Option, ValueContainer: LimitedChipsContainer
                                                      }}
                                                      isSearchable="true"
                                                      onChange={(value) =>
                                                        onManufactureSelect(value)}
                                                      value={selectedManufactureOption.optionMfSelected}
                                                      className='claim_info'
                                                      maxMenuHeight={220}
                                                      styles={{
                                                        menu: (provided) => ({
                                                          ...provided,
                                                          zIndex: 99,
                                                        }),
                                                      }}
                                                    />
                                                    {(error) && (ManufactureIds == '' || ManufactureIds== undefined) && <div className="text-danger f-12">
                                                      Manufacturer selection is required </div>}
                                                  </FormGroup>
                                                </Col>

                                                <Col md={3} lg={6} sm={6} xs={6}>
                                                  {' '}
                                                  <FormGroup>
                                                    <Label for='coveredentity'></Label>
                                                    <Label
                                                      htmlFor="Pharmacy" for='reportname'
                                                      className={focusedInput === 'Pharmacy' ? 'label-focus' : ''}
                                                    >Pharmacy*</Label>
                                                    <ReactSelect
                                                      name='coveredentity'
                                                      id='coveredentity'
                                                      options={pharmacyData}
                                                      isMulti
                                                      closeMenuOnSelect={false}
                                                      hideSelectedOptions={false}
                                                      optionSelected={true}
                                                      onFocus={() => handleFocus('Pharmacy')}
                                                      onBlur={handleBlur}
                                                      components={{
                                                        Option, ValueContainer: LimitedChipsContainer
                                                      }}
                                                      isSearchable="true"
                                                      value={selectedpharmacyOption.optionPhSelected}
                                                      onChange={onPharamcyChange}
                                                      className='claim_info'
                                                      maxMenuHeight={220}
                                                      styles={{
                                                        menu: (provided) => ({
                                                          ...provided,
                                                          zIndex: 99,
                                                        }),
                                                      }}
                                                    />
                                                    {(error) && (clientId == '' || clientId == undefined) && <div className="text-danger f-12">
                                                      Pharmacy is required </div>}
                                                  </FormGroup>
                                                </Col>


                                                <Col md={3} lg={6} sm={6} xs={6}  >
                                                  {' '}

                                                  <FormGroup className='controls'>
                                                    <Label for='reportname'></Label>
                                                    <Label
                                                      htmlFor="ClaimType" for='reportname'
                                                      className={focusedInput === 'ClaimType' ? 'label-focus' : ''}
                                                    >Claim Type*</Label>
                                                    <Field
                                                      name='ClaimType'
                                                      type='text'
                                                      value={claimid}
                                                      onChange={(e) => handleClaimTypeChange(e)}
                                                      component={FormikSelectField}
                                                      options={ClaimTypes}
                                                      onFocus={() => handleFocus('ClaimType')}
                                                      onBlur={handleBlur}
                                                      defaultoption='--select--'
                                                      keys={{
                                                        id: 'claimTypeid',
                                                        label: 'claimTypeName',
                                                      }}
                                                    />
                                                    {(error) && (claimid == '' || claimid== undefined) && <div className="text-danger f-12">
                                                      Claims Type is required </div>}
                                                  </FormGroup>
                                                </Col>

                                              </Row>

                                              <Row>
                                                <Col md={3} lg={6} sm={6} xs={6}>

                                                  <div className='d-flex justify-content-between align-items-center re_con'>
                                                    <Label className='' style={{ fontSize: "16px" }}>Net Claims <span style={{ display: "block" }}>If this is Enabled it only sends <br />
                                                      B1 net claims only.</span></Label>
                                                    <FormGroup switch style={{ paddingRight: "15px"}} className='form-check form-switch'>

                                                      <Input
                                                      className='form-check-input'
                                                        type="switch"
                                                        checked={state}
                                                        onClick={handelNetClaim}
                                                      />

                                                    </FormGroup>
                                                  </div>

                                                </Col>
                                              </Row>
                                            </div>













                                          </div>
                                     

                                  
                                  </div>
                                </div>
                              }
                              {currentStep === 3 &&
                                <div>
                                  <div className="d-flex justify-content-between section-devider-border">
                                    <h3 className='font-20'>Report Settings</h3>
                                  </div>
                                  <Col md={6} lg={12} sm={6} xs={6} className='pt-3'>
                                    <FormGroup>
                                      <Label for='reportname'>Auto Send</Label>
                                      <label className='toggle-label'>
                                        <input
                                          type='checkbox'
                                          id="hrsamedicaid"
                                          checked={isChecked}
                                          onChange={handleToggle}
                                        />
                                        <span className='back'>
                                          <span className='toggle'></span>
                                          <span className='label on'>OFF</span>
                                          <span className='label off'>ON</span>
                                        </span>
                                      </label>
                                    </FormGroup>
                                  </Col>

                                  <Col md={6} lg={12} sm={6} xs={6}  >
                                    {' '}

                                    <FormGroup className='controls textarea_info'>
                                      <Label for='reportname'>Comments Optional</Label>
                                      <Input
                                        id='reportname'
                                        name='reportname'
                                        type='textarea'
                                        placeholder="Add comment about report"
                                        value={comments}
                                        onChange={handleComments}
                                        autoComplete='off'
                                      >


                                      </Input>
                                    </FormGroup>
                                  </Col>

                                </div>
                              }
                            </div>
                          )}
                        </div>



                      </div>

                    </div>


                  </div>

                </div>

              </CardBody>
            </Card>

            <div>
              <footer>
                <div hidden={""}>
                  <Row>
                    <Col sm={0} className="d-flex" style={{ justifyContent: "flex-end" }}>
                      <Label>{""}</Label>
                      <Button 
                        // hidden={isAddPermission}
                        // disabled={isSubmitting}
                        color="primary"
                        className="reset"
                        onClick={handleDiscard}

                      // disabled={!coveredEntityid}
                      // hidden={!hasPermission("ADD")}
                      >

                        Discard
                      </Button>
                      {currentStep > 2 ?
                        <Button
                          color="primary"
                          className="search"
                          onClick={handleSave}
                        >
                          Save
                        </Button>
                        :
                        <Button
                          // disabled={currentStep == 3}
                          // hidden={isAddPermission}
                          // disabled={isSubmitting}
                          color="primary"
                          className="search"
                          onClick={nextStep}

                        // disabled={!coveredEntityid}
                        // hidden={!hasPermission("ADD")}
                        >

                          Next
                        </Button>
                      }
                    </Col>
                  </Row>
                </div>

                {/* <div className="stepper-footer">
        <button onClick={prevStep} disabled={currentStep === 0}>
          Previous
        </button>
        <button onClick={nextStep} disabled={currentStep === steps.length - 1}>
          Next
        </button>
      </div> */}
              </footer>




            </div>
          </div>


        )
      }
    }
    ></Formik>
  )
}

export default AddConfigurations