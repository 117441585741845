import React from "react";
import { Input, Label } from "reactstrap";
import { ErrorMessage } from "formik";

const FormikSelectField = ({
  field,
  form,
  disabled = false,
  label,
  flag,
  ...props
}) => {
  const { id, options, defaultoption, keys } = props;
  const { name } = field;
  return (
    <>
      {label && (
        <Label for={id} className="label-color">
          {label}
        </Label>
      )}
      <Input
        id={id}
        {...field}
        {...props}
        type="select"
        // invalid={Boolean(touched[name] && errors[name])}
        disabled={disabled}
      >
        <option value="">{defaultoption}</option>
        {options.map((option) => {
          if (keys) {
            // eslint-disable-next-line no-shadow
            const { id, label } = keys;
            return (
              <option value={option[id]} key={`${option[id]}_${option[label]}`}>
                {option[label]}
              </option>
            );
          }
          return (
            <option value={option} key={`option_${option}`}>
              {flag ? `<img src={googleImage}` : option}
            </option>
          );
        })}
      </Input>
      <ErrorMessage
        name={name}
        render={(msg) => (
          <div className="text-danger font-size-12 position-absolute">
            {msg}
          </div>
        )}
      />
    </>
  );
};

export default FormikSelectField;
