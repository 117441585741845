import ReactStrapTable from 'components/reactStrapTable';
import React, { useEffect, useState } from 'react'
import EditIcon from 'assets/icon/icon-icon-options-edit.svg';
import ConfigIcon from "assets/icon/config-setting-icon.svg";
// import ConfigIconHover from "assets/icon/config-setting-icon-hover.svg";
// import EditHoverIcon from 'assets/icon/icon-icon-options-edit_hover.svg';
import Download from 'assets/icon/Download.svg';
import DownloadHover from "assets/icon/download_hover.svg";
import HoverImage from 'react-hover-image/build';
import { Button } from 'reactstrap';
import { dateFormate, currencyFormat, cellRendererCheck } from 'utils/columns';
import axios from 'axios';
import CellEditRender from 'components/reactStrapTable/components/CellEditRender';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Loader from 'components/Loader';


let tableDataBackup=[]

const ConfigurationTable = (props) => {
    const {

        manvalConfigedit,
        loading,
        manvalConfig,
        manvalPharmacy,
        onExport,
        collapseSider,
        manval,
        data,
        filters,
        totalSize,
        // onPageChange
    } = props;
tableDataBackup=data
    const [tableData, setTableData] = useState(tableDataBackup);
    const [state, updateState] = useState({
        filters: filters,
    });
    const handleConfig = async (rowData) => {
        await manvalConfig();

    }
    const handelAddCoveredEntity = async (rowData) => {
        await manvalPharmacy();

    }
    const toggleSider = async () =>{
        await manval();
       }

    const handleEditConfig = async () => {
        await manvalConfigedit();
    }
    // const handleExport = async (rowData) => {
    //      try{
    //         const action = await axios.get(`https://localhost:7113/api/Report/download/${rowData.reportTypeID}`);
    //     }
    //     catch(error){
    //         console.log(error)

    //     }
       
    // };
   
    
    const renderEditButton = (rowData) => {
        // if (hasPermission('EDT'))
        return <Button
            className='text-primary p-0 text-decoration-none'
            color='link'
            onClick={() => handleExport(rowData)}
            title='Edit Covered Entity'

        >
            <HoverImage src={Download}
                hoverSrc={DownloadHover}
                alt='' />
        </Button>
    }
    useEffect(() => {
        setTableData(data);
        tableDataBackup = data;
      }, [data]);
    // const renderConfigButton = () => {

    //     return <Button
    //         className='text-primary p-0 text-decoration-none'
    //         color='link'
    //         onClick={() => handleConfig()}
    //         title='Client Configuration'

    //     >
    //         <HoverImage
    //             src={ConfigIcon}
    //             hoverSrc={ConfigIconHover}
    //             alt='' />
    //     </Button>
    // }

    const cellRenderEdit = (rowData) => {
        return (
            <div className='d-flex align-items-center'>
                {renderEditButton(rowData)}
                {/* {renderConfigButton(rowData)} */}
                {/* {renderCommentsButton(rowData)} */}
                {/* {renderViewButton(rowData)} */}
            </div>
        );
    };
    const formatter = (row, col, formatterType, alwaysEditableCheckbox) => {

        return (
            <CellEditRender
                row={row}
                col={col}
                formatterType={formatterType}
                // onChange={updateRowValue}
                alwaysEditableCheckbox={alwaysEditableCheckbox}
            />
        );
    };
    
    const ManufacturesArrayList=(row)=>{
        const displayLimit = 1;
        const displayedManufacturers = row.reportConfigMfgMaps.slice(0, displayLimit);
        const hiddenManufacturers = row.reportConfigMfgMaps.slice(displayLimit);
        return(
            <div>
      {displayedManufacturers.map((manufacturer, index) => (
        <Badge
          key={index}
          pill
          bg="secondary"
          style={{
            marginRight: '8px',
            padding: '10px',
            color:"#555555",
            margin:"auto",
            width:"auto",
          }}
        >
{manufacturer.manufacturerName}
        </Badge>
      ))}
      <div>
      {hiddenManufacturers.length > 0 && (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="manufacturers-tooltip">
              <div style={{ textAlign: 'left' }}>
                {hiddenManufacturers.map((manufacturer, index) => (
                 (manufacturer.manufacturerName)
                )).join(", ")}
                
              </div>
            </Tooltip>
          }
        >
          <span style={{ color: '#702C62', cursor: 'pointer', fontSize:"12px", fontWeight:"500", textDecoration:"underline", }}>
            +{hiddenManufacturers.length} More
          </span>
        </OverlayTrigger>
      )}
      </div>
       
            </div>
        )
    }
    const PharmacyArrayList=(row)=>{
        const displayLimit = 1;
        // const reportConfigClientMaps=[
        //     {
        //         "reportConfigClientMapID": 76,
        //         "reportConfigID": 158,
        //         "clientID": 1,
        //         "phid": 3,
        //         "phName": "SHATTO'S FRONTIER DRUG",
        //         "inactiveFlag": "N"
        //     },
        //     {
        //         "reportConfigClientMapID": 77,
        //         "reportConfigID": 158,
        //         "clientID": 2,
        //         "phid": 5,
        //         "phName": "SAFEWAY INC wesrdtfvgybhujnkmlsdfgh bjkm",
        //         "inactiveFlag": "N"
        //     },
        //     {
        //         "reportConfigClientMapID": 77,
        //         "reportConfigID": 158,
        //         "clientID": 2,
        //         "phid": 5,
        //         "phName": "SAFEWAY sdfghjasdfghjkm,zxcvbnm",
        //         "inactiveFlag": "N"
        //     },
        //     {
        //         "reportConfigClientMapID": 77,
        //         "reportConfigID": 158,
        //         "clientID": 2,
        //         "phid": 5,
        //         "phName": "SAFEWAY wertyuikl sdfghjrtyujikl",
        //         "inactiveFlag": "N"
        //     }
        // ]
        const displayedManufacturers = row.reportConfigClientMaps.slice(0, displayLimit);
        const hiddenManufacturers = row.reportConfigClientMaps.slice(displayLimit);
        return(
            <div>
      {displayedManufacturers.map((manufacturer, index) => (
        <Badge
          key={index}
          pill
          bg="secondary"
          style={{
            marginRight: '8px',
            padding: '10px',
            color:"#555555",
            margin:"auto",
            width:"auto"
          }}
        >
{manufacturer.phName}
        </Badge>
      ))}
      <div>
      {hiddenManufacturers.length > 0 && (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="manufacturers-tooltip">
              <div style={{ textAlign: 'left' }}>
                {hiddenManufacturers.map((manufacturer, index) => (
                 (manufacturer.phName)
                )).join(", ")}
              </div>
            </Tooltip>
          }
        >
          <span style={{ color: '#702C62', cursor: 'pointer', fontSize:"12px", fontWeight:"500", textDecoration:"underline", }}>
            +{hiddenManufacturers.length} More
          </span>
        </OverlayTrigger>
      )}
      </div>
       
            </div>
        )
    }



    let mainCol = [
        {
            dataField: 'edit',
            text: 'Options',
            // cellRenderer: (rowData) => cellRenderEdit(rowData),
            width: 100,
            show: true,
            disabled: true,
            hideColFilter: true,
            action: true,
        },

        {
            dataField: 'reportName',
            text: 'Report Name',
            sort: true,
            width: 140,
            show: true,
            inputType: 'text',
            hideColFilter: true,
            cellRenderer: (row) => cellRendererCheck(row.reportName),
        },
        {
            dataField: 'reportTypeName',
            text: 'Report Type',
            sort: true,
            width: 140,
            show: true,
            inputType: 'text',
            hideColFilter: true,
            cellRenderer: (row) => cellRendererCheck(row.reportTypeName),
        },
        {
            dataField: 'frequencyDesc',
            text: 'Frequency',
            sort: false,
            width: 140,
            show: true,
            inputType: 'text',
            hideColFilter: true,
            cellRenderer: (row) => cellRendererCheck(row.frequencyDesc),
        },
        {
            dataField: 'startDate',
            text: 'Start Date',
            sort: false,
            width: 200,
            show: true,
            inputType: 'text',
            hideColFilter: true,
            cellRenderer: (row) => dateFormate(row.startDate),
        },
        {
            dataField: 'endDate',
            text: 'Term Date',
            sort: false,
            width: 200,
            show: true,
            inputType: 'text',
            hideColFilter: true,
            cellRenderer: (row) => dateFormate(row.endDate),
        },
        {
            dataField: 'pharmacyAddress',
            text: 'Manufactures',
            sort: false,
            width: 200,
            show: true,
            inputType: 'text',
            hideColFilter: true,
            edit:true,
            cellRenderer: (row,col) => ManufacturesArrayList(row),
        },
        {
            dataField: 'pharmacyLiveDate',
            text: 'Pharmacy',
            sort: false,
            width: 140,
            show: true,
            inputType: 'text',
            hideColFilter: true,
            cellRenderer: (row,col) => PharmacyArrayList(row),
        },
        {
            dataField: 'claimTypeDesc',
            text: 'Claim Type',
            sort: false,
            width: 140,
            show: true,
            inputType: 'text',
            hideColFilter: true,
            cellRenderer: (row) => cellRendererCheck(row.claimTypeDesc),
        },
        {
            dataField: 'netClaims',
            text: 'Net Claims Only',
            sort: false,
            width: 140,
            show: true,
            inputType: 'text',
            hideColFilter: true,
            cellRenderer: (row) => cellRendererCheck(row.netClaims=="Y"?"Yes":"No"),
        },
     
        {
            dataField: 'autoSend',
            text: 'Auto Send',
            sort: false,
            width: 140,
            show: true,
            inputType: 'text',
            hideColFilter: true,
            cellRenderer: (row) => cellRendererCheck(row.autoSend=="Y"?"Yes":"No"),
        },

    ];
    const setState = (state) => updateState((pre) => ({ ...pre, ...state }));
    // const onHandlePageChange = (filter, hasColFilter, colChangedFilter) => {
 
    //     if (hasColFilter) {
    //         setState({
    //             filters: filter,
    //         });
    //         let colFilterOn = { ...filter, filters: colChangedFilter };
    //         onPageChange(colFilterOn, true);
    //     } else {
    //         setState({
    //             filters: filter,
    //         });
    //         onPageChange(filter);
    //     }
    // };
 
    // const onPageSizeChange = (filter, hasColFilter, colChangedFilter) => {
    //     if (hasColFilter) {
    //         setState({
    //             filters: filter,
    //         });
    //         let colFilterOn = { ...filter, filters: colChangedFilter };
    //         return onPageChange(colFilterOn, true);
    //     }
    //     setState({
    //         filters: filter,
    //     });
    //     onPageChange(filter);
    // };
    console.log(tableData)
    return (

        <div>
                {/* {loading&&<Loader opacity={0.01}color='black'/>} */}
            <div
                className='table-card scroll-table position-relative border-0 rounded pt-0'
                fixed-height={tableData.length > 0 ? 'fixed-height' : 'default'}
            >
                <ReactStrapTable
                    id='configuration-table'
                    height={550}
                    fixedColumn
                    width={1200}
                    // reset={reset}
                    data={tableData}
                    columns={mainCol}
                    // add={"Add Covered Entity"}
                    // addbutton={true}
                    handelAddCoveredEntity={handelAddCoveredEntity}
                    onExport={onExport}
                    // filters={2}

                    filters={filters}
                    totalRecords={totalSize}
                    pageSize={filters.pageSize}
                    currentPage={filters.currentPage}
                    // handleSort={onHandleSort}
                    // onPageChange={onHandlePageChange}
                    // onPageSizeChange={onPageSizeChange}
                    // handleSort={onHandleSort}
                    // onPageChange={onHandlePageChange}
                    // onPageSizeChange={onPageSizeChange}
                    // onFilterCancel={handleFilterCancel}
                    // onSearchColumnFilters={handleSearchColumnFilters}
                    hasExportPermission={true}

                    collapseSider={collapseSider}
                    toggleSider={toggleSider}
                />
            </div>
        </div>
    )
}

export default ConfigurationTable